import React, {Component} from 'react';
import {Container, Header, Divider} from "semantic-ui-react";

class Unauthorized extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container text>
                <br />
                <Header as="h1">403 Forbidden</Header>
                <Divider />
                <p>
                    Either you do not have permission to access this service, or your session has expired.
                    <br/><br/>
                    Please click <a onClick={() => window.location.reload()}>here</a> to log back in, or request access on the Slack channel #mss-me-support.
                </p>
            </Container>
        );
    }
}

export default Unauthorized;
