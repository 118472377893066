import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Checkbox, Divider, Form, Grid, Icon, Input, Label, Message, Popup, Select, TextArea} from "semantic-ui-react";
import _ from "lodash";
import ContingentButton from "../../ContingentButton";

const ORDER_OPTIONS = [
    {key: "", text: "", value: ""},
    {key: "last", text: "Last", value: "last"},
    {key: "first", text: "First", value: "first"}
]

const PATH_OPTIONS = [
    {key: "token_isp", text: "/token_isp", value: "/token_isp"},
    {key: "token_spe", text: '/token_spe', value: "/token_spe"},
    {key: "token_ngtv", text: "/token_ngtv", value: "/token_ngtv"}
];

export default function TokenStreamSettingsDetailView(props) {
    const [initialStreamConcurrencySetting, setInitialStreamConcurrencySetting] = useState({});
    const [requestorOptions, setRequestorOptions] = useState([]);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [ruleId, setRuleId] = useState("");
    const [description, setDescription] = useState("");
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [selectedRequestors, setSelectedRequestors] = useState([]);
    const [enableStreamConcurrency, setEnableStreamConcurrency] = useState(false);
    const [heartbeat, setHeartbeat] = useState(0);
    const [streams, setStreams] = useState(0);
    const [scEnabled, setScEnabled] = useState(false);
    const [order, setOrder] = useState("");
    const [grace, setGrace] = useState(0);
    const [renewal, setRenewal] = useState(0);
    const [ttl, setTtl] = useState(0);

    useEffect(function selectedSettingUpdated() {
        setInitialStreamConcurrencySetting(Object.assign({}, props.selectedStreamConcurrencySetting));
        const selectedSetting = Object.assign({}, props.selectedStreamConcurrencySetting);
        const sc = Object.assign({}, props.selectedStreamConcurrencySetting.sc || {});
        const tt = Object.assign({}, props.selectedStreamConcurrencySetting.tt || {});
        setRuleId(selectedSetting.hasOwnProperty("ruleId") ? selectedSetting.ruleId : "");
        setDescription(selectedSetting.hasOwnProperty("description") ? selectedSetting.description : "");
        setSelectedPaths(selectedSetting.hasOwnProperty("path") ? selectedSetting.path : []);

        if (selectedSetting.hasOwnProperty("resource")) {
            setSelectedResources(selectedSetting.resource);
            const updatedResourceOptions = [];
            for (const resource of selectedSetting.resource) {
                updatedResourceOptions.push({key: resource, text: resource, value: resource});
            }
            setResourceOptions(updatedResourceOptions);
        } else {
            setSelectedResources([]);
            setResourceOptions([]);
        }

        if (selectedSetting.hasOwnProperty("requestor")) {
            setSelectedRequestors( selectedSetting.requestor);
            const updatedRequestorOptions = [];
            for (const requestor of selectedSetting.requestor) {
                updatedRequestorOptions.push({key: requestor, text: requestor, value: requestor});
            }
            setRequestorOptions(updatedRequestorOptions);
        } else {
            setSelectedRequestors([]);
            setRequestorOptions([]);
        }

        setEnableStreamConcurrency(Object.keys(sc).length > 0);
        setHeartbeat(sc.hasOwnProperty("heartbeat") ? sc.heartbeat : 60);
        setStreams(sc.hasOwnProperty("streams") ? sc.streams : 5);
        setScEnabled(sc.enabled === true);
        setOrder(sc.hasOwnProperty("order") ? sc.order : "last");
        setGrace(sc.hasOwnProperty("grace") ? sc.grace : 30);
        setRenewal(tt.hasOwnProperty("renewal") ? tt.renewal : 86400);
        setTtl(tt.hasOwnProperty("ttl") ? tt.ttl : 3600);
        console.log(props.selectedStreamConcurrencySetting)
    }, [JSON.stringify(props.selectedStreamConcurrencySetting)]);

    useEffect(function updateUnsavedChanges() {
        const initialSetting = Object.assign({
            ruleId: "",
            description: "",
            requestor: [],
            resource: [],
            tt: {
                renewal: 86400,
                ttl: 3600
            },
            sc: {
                heartbeat: 60,
                streams: 5,
                order: "last",
                grace: 30,
                enabled: false
            }
        }, initialStreamConcurrencySetting);
        const ruleIdUpdated = ruleId !== initialSetting.ruleId;
        const descriptionUpdated = description !== initialSetting.description;
        const enableStreamConcurrencyUpdated = enableStreamConcurrency !== initialStreamConcurrencySetting.hasOwnProperty("sc");
        const heartbeatUpdated = initialStreamConcurrencySetting.hasOwnProperty("sc") && heartbeat !== initialSetting.sc.heartbeat;
        const streamsUpdated = initialStreamConcurrencySetting.hasOwnProperty("sc") && streams !== initialSetting.sc.streams;
        const scEnabledUpdated = initialStreamConcurrencySetting.hasOwnProperty("sc") && scEnabled !== initialSetting.sc.enabled;
        const orderUpdated = initialStreamConcurrencySetting.hasOwnProperty("sc") && initialSetting.sc.order !== order;
        const graceUpdated = initialStreamConcurrencySetting.hasOwnProperty("sc") && initialSetting.sc.grace !== grace;
        const renewalUpdated = renewal !== initialSetting.tt.renewal;
        const ttlUpdated = ttl !== initialSetting.tt.ttl;
        let pathsUpdated = _.difference(initialSetting.path, selectedPaths).length > 0 || _.difference(selectedPaths, initialSetting.path) > 0;
        let requestorsUpdated = false;
        for (const requestor of initialSetting.requestor) {
            if (requestorsUpdated) {
                break;
            }
            requestorsUpdated ||= !selectedRequestors.includes(requestor);
        }
        for (const requestor of selectedRequestors) {
            if (requestorsUpdated) {
                break;
            }
            requestorsUpdated ||= !initialSetting.requestor.includes(requestor);
        }

        let resourcesUpdated = false;
        for (const resource of initialSetting.resource) {
            if (resourcesUpdated) {
                break;
            }
            resourcesUpdated ||= !selectedResources.includes(resource);
        }
        for (const resource of selectedResources) {
            if (resourcesUpdated) {
                break;
            }
            resourcesUpdated ||= !initialSetting.resource.includes(resource);
        }
        const unsavedChanges = ruleIdUpdated || descriptionUpdated || enableStreamConcurrencyUpdated || heartbeatUpdated || streamsUpdated || scEnabledUpdated || orderUpdated || graceUpdated || renewalUpdated || ttlUpdated || pathsUpdated || requestorsUpdated || resourcesUpdated;
        props.setIsSelectedSettingsUpdated(unsavedChanges);
    }, [JSON.stringify(initialStreamConcurrencySetting), ruleId, description, enableStreamConcurrency, heartbeat, streams, scEnabled, order, grace, renewal, ttl, JSON.stringify(selectedPaths), JSON.stringify(selectedResources), JSON.stringify(selectedRequestors)])

    const onClickSave = () => {
        const streamCountingSetting = {
            ruleId,
            description,
            path: selectedPaths,
            ...selectedRequestors.length > 0 && {requestor: selectedRequestors},
            ...selectedResources.length > 0 && {resource: selectedResources},
            tt: {
                renewal: parseInt(renewal),
                ttl: parseInt(ttl)
            },
            ...enableStreamConcurrency && {
                sc: {
                    streams: parseInt(streams),
                    order,
                    heartbeat: parseInt(heartbeat),
                    grace: parseInt(grace),
                    enabled: scEnabled
                }
            },
            index: initialStreamConcurrencySetting.index
        };

        props.onClickSave(streamCountingSetting);
    };

    return (
        <Grid>
            <Grid.Column width={16}>
                {
                    props.loading ?
                        <Message color="yellow" icon>
                            <Icon name="spinner" loading/>
                            <Message.Content>Loading stream concurrency settings...</Message.Content>
                        </Message> :
                    props.loadingErrorMessage ?
                        <Message color="red" icon>
                            <Icon name="exclamation"/>
                            <Message.Content>{props.loadingErrorMessage}</Message.Content>
                        </Message> : ""
                }
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            {
                                !props.loading ?
                                    <Popup
                                        open={props.unsavedChanges && !props.ruleIds.includes(ruleId)}
                                        content="You have made unsaved changes."
                                        trigger={
                                            <ContingentButton
                                                primary
                                                onClick={onClickSave}
                                                disabled={props.ruleIds.includes(ruleId) || ruleId.length < 1 || selectedPaths.length < 1 || selectedResources.length < 1}
                                                service={props.service}
                                                module={props.module}
                                                scope="all"
                                                allPermissions={props.permissions}
                                                user={props.user}
                                            >Save Stream Settings</ContingentButton>
                                        }
                                        position="right center"
                                    /> : ""
                            }
                        </Form.Field>
                        <Form.Field>
                            {
                                props.selectedStreamConcurrencySetting.hasOwnProperty("ruleId") && !props.loading ?
                                    <ContingentButton
                                        floated="right"
                                        color="red"
                                        onClick={() => props.onClickDelete(initialStreamConcurrencySetting.index)}
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        allPermissions={props.permissions}
                                        user={props.user}
                                    >Delete Stream Settings</ContingentButton> : ""
                            }
                        </Form.Field>
                    </Form.Group>
                    {
                        !props.selectedStreamConcurrencySetting.hasOwnProperty("ruleId") && !props.newClicked ?
                            props.loading ? "" :
                            <Message icon color="blue">
                                <Icon name="info" />
                                <Message.Content>Please select a stream concurrency setting from the selector, or click "Create New" in order to continue.</Message.Content>
                            </Message> :
                            <div>
                                {
                                    props.saving ?
                                        <Message color="yellow" icon>
                                            <Icon name="spinner" loading />
                                            <Message.Content>Saving stream concurrency settings...</Message.Content>
                                        </Message> :
                                        props.saveSuccessMessage ?
                                            <Message icon color="green">
                                                <Icon name="check" />
                                                <Message.Content>{props.saveSuccessMessage}</Message.Content>
                                            </Message> :
                                            props.saveErrorMessage ?
                                                <Message icon color="red">
                                                    <Icon name="exclamation" />
                                                    <Message.Content>{props.saveErrorMessage}</Message.Content>
                                                </Message> : ""
                                }
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <Popup
                                            open={props.ruleIds.includes(ruleId)}
                                            trigger={
                                                <Form.Field
                                                    label="Rule ID"
                                                    control={Input}
                                                    value={ruleId}
                                                    onChange={(event, {value}) => setRuleId(value)}
                                                    error={props.ruleIds.includes(ruleId)}
                                                    readOnly={!props.userCanEdit}
                                                />
                                            }
                                            content="This rule ID is already in use."
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Form.Field
                                            label="Enable Stream Concurrency"
                                            control={Checkbox}
                                            toggle
                                            checked={enableStreamConcurrency}
                                            floated="right"
                                            onClick={(event, {checked}) => {
                                                if (props.userCanEdit) {
                                                    setEnableStreamConcurrency(checked);
                                                }
                                            }}
                                            readOnly={!props.userCanEdit}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Description"
                                        control={TextArea}
                                        value={description}
                                        onChange={(event, {value}) => setDescription(value)}
                                        readOnly={!props.userCanEdit}
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    {
                                        props.userCanEdit ?
                                            <Form.Field
                                                control={Select}
                                                label="Service Paths"
                                                value={selectedPaths}
                                                options={PATH_OPTIONS}
                                                multiple
                                                selection={props.userCanEdit}
                                                search={props.userCanEdit}
                                                fluid
                                                onChange={(event, {value}) => setSelectedPaths(value)}
                                            /> :
                                            <Form.Field>
                                                <label>Service Paths</label>
                                                {
                                                    selectedPaths.map(path => <Label circular>{path}</Label>)
                                                }
                                            </Form.Field>
                                    }
                                    {
                                        props.userCanEdit ?
                                            <Form.Field
                                                control={Select}
                                                label="Requestor IDs"
                                                value={selectedRequestors}
                                                options={requestorOptions}
                                                multiple
                                                selection
                                                search={props.userCanEdit}
                                                allowAdditions={props.userCanEdit}
                                                fluid
                                                onChange={(event, {value}) => setSelectedRequestors(value)}
                                                onAddItem={(event, {value}) => setRequestorOptions(requestorOptions.concat([{key: value, text: value, value}]))}
                                            /> :
                                            <Form.Field>
                                                <label>Requestor IDs</label>
                                                {
                                                    selectedRequestors.map(requestor => <Label circular>{requestor}</Label>)
                                                }
                                            </Form.Field>
                                    }
                                    {
                                        props.userCanEdit ?
                                            <Form.Field
                                                control={Select}
                                                label="Resource IDs"
                                                value={selectedResources}
                                                options={resourceOptions}
                                                multiple
                                                selection
                                                search={props.userCanEdit}
                                                allowAdditions={props.userCanEdit}
                                                fluid
                                                onChange={(event, {value}) => setSelectedResources(value)}
                                                onAddItem={(event, {value}) => setResourceOptions(resourceOptions.concat([{key: value, text: value, value}]))}
                                            /> :
                                            <Form.Field>
                                                <label>Resource IDs</label>
                                                {
                                                    selectedPaths.map(resource => <Label circular>{resource}</Label>)
                                                }
                                            </Form.Field>
                                    }

                                </Form.Group>
                                <Divider horizontal>Turner Token</Divider>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Max Renewal</label>
                                        <Input
                                            type="number"
                                            value={renewal}
                                            number
                                            onChange={(event, {value}) => setRenewal(value)}
                                            label={{content: "seconds"}}
                                            labelPosition="right"
                                            readOnly={!props.userCanEdit}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Turner Token TTL</label>
                                        <Input
                                            type="number"
                                            labelPosition="right"
                                            number
                                            value={ttl}
                                            onChange={(event, {value}) => setTtl(value)}
                                            label={{content: "seconds"}}
                                            readOnly={!props.userCanEdit}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                {
                                    enableStreamConcurrency ?
                                        <>
                                            <Divider horizontal>Stream Concurrency</Divider>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    type="number"
                                                    label="Number of Streams"
                                                    control={Input}
                                                    number
                                                    value={streams}
                                                    onChange={(event, {value}) => setStreams(value)}
                                                    readOnly={!props.userCanEdit}
                                                />
                                                <Form.Field
                                                    label="Order"
                                                    control={props.userCanEdit ? Select : Input}
                                                    value={order}
                                                    error={order === ""}
                                                    onChange={
                                                        (event, {value}) => {
                                                            if (props.userCanEdit) {
                                                                setOrder(value);
                                                            }
                                                        }
                                                    }
                                                    options={ORDER_OPTIONS}
                                                    readOnly={!props.userCanEdit}
                                                />
                                                <Form.Field>
                                                    <label>Heartbeat</label>
                                                    <Input
                                                        type="number"
                                                        value={heartbeat}
                                                        onChange={(event, {value}) => setHeartbeat(value)}
                                                        number
                                                        label={{content: "seconds"}}
                                                        labelPosition="right"
                                                        readOnly={!props.userCanEdit}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Grace</label>
                                                    <Input
                                                        type="number"
                                                        value={grace}
                                                        onChange={(event, {value}) => setGrace(value)}
                                                        number
                                                        label={{content: "seconds"}}
                                                        labelPosition="right"
                                                        readOnly={!props.userCanEdit}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label="Enabled"
                                                            toggle
                                                            checked={scEnabled}
                                                            onClick={(event, {checked}) => {
                                                                if (props.userCanEdit) {
                                                                        setScEnabled(checked);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </Form.Field>
                                                </Form.Field>
                                            </Form.Group>
                                        </> : ""
                                }
                            </div>
                    }
                </Form>

            </Grid.Column>
        </Grid>
    );
};

TokenStreamSettingsDetailView.propTypes = {
    selectedStreamConcurrencySetting: PropTypes.object.isRequired,
    setIsSelectedSettingsUpdated: PropTypes.func.isRequired
}
