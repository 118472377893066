import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ContingentButton from "../../ContingentButton";

export default function TokenStreamSettingsSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredStreamConcurrencySettings, setFilteredStreamConcurrencySettings] = useState([]);

    useEffect(function updateFilteredStreamConcurrencySettings() {
        if (filter.length > 0) {
            const updatedFilteredStreamConcurrencySettings = [];
            for (const streamConcurrencySetting of props.streamConcurrencySettings) {
                if (streamConcurrencySetting.hasOwnProperty("ruleId") && streamConcurrencySetting.ruleId.toLowerCase().includes(filter)) {
                    updatedFilteredStreamConcurrencySettings.push(streamConcurrencySetting);
                } else if (streamConcurrencySetting.hasOwnProperty("description") && streamConcurrencySetting.description.toLowerCase().includes(filter)) {
                    updatedFilteredStreamConcurrencySettings.push(streamConcurrencySetting);
                }
            }
            setFilteredStreamConcurrencySettings(updatedFilteredStreamConcurrencySettings);
        } else {
            setFilteredStreamConcurrencySettings(props.streamConcurrencySettings);
        }

    }, [filter, JSON.stringify(props.streamConcurrencySettings)]);

    const onDragEnd = result => {
        if (filter) {
            props.toast("Error", "You must clear the filter before you can modify the order of stream concurrency settings.");
            return;
        }

        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        const updatedStreamConcurrencySettings = props.streamConcurrencySettings.slice();

        const [removed] = updatedStreamConcurrencySettings.splice(source.index, 1);
        updatedStreamConcurrencySettings.splice(destination.index, 0, removed);
        props.setStreamConcurrencySettings(updatedStreamConcurrencySettings);
    };

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <ContingentButton
                                icon
                                secondary
                                onClick={() => props.setNewClicked(true)}
                                fluid
                                service={props.service}
                                module={props.module}
                                scope="all"
                                allPermissions={props.permissions}
                                user={props.user}
                            ><Icon name="plus"/> Create New</ContingentButton>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshStreamConcurrencySettings()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72.5vh", overflowY: "auto"}}>
                    <Menu vertical fluid>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {
                                filteredStreamConcurrencySettings.length > 0 ?
                                    <Droppable droppableId={"droppable"}>
                                        {
                                            provided =>
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        filteredStreamConcurrencySettings.map((setting, index) =>
                                                            <Draggable key={`setting-${index}`} draggableId={`setting-${index}`} index={index}>
                                                                {
                                                                    provided =>
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Menu.Item
                                                                                innerRef={provided.innerRef}
                                                                                {...provided.droppableProps}
                                                                                active={JSON.stringify(props.selectedStreamConcurrencySetting.index) === JSON.stringify(setting.index)}
                                                                                onClick={
                                                                                    () => {
                                                                                        console.log(setting);
                                                                                        props.setSelectedStreamConcurrencySetting(setting);
                                                                                    }
                                                                                }
                                                                                fluid
                                                                            >
                                                                                <h5>{setting.ruleId}</h5>
                                                                                <>{setting.description}</>
                                                                                {provided.placeholder}
                                                                            </Menu.Item>
                                                                        </div>

                                                                }
                                                            </Draggable>
                                                        )
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                        }
                                    </Droppable> :
                                    <Message icon color="yellow">
                                        <Icon name="warning" />
                                        <Message.Content>No stream concurrency settings match your current search filter.</Message.Content>
                                    </Message>
                            }
                        </DragDropContext>
                    </Menu>
                </Container>
            </Grid.Column>
        </Grid>
    );
};
