import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";

export default function PermissionSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredPermissions, setFilteredPermissions] = useState([]);

    useEffect(function updateFilteredPermissions() {
        console.log("PermissionSelector.updateFilteredPermissions permissions: ", props.permissions);

        if (filter.length > 0) {
            const updatedPermissions = [];
            for (const permission of props.permissions) {
                if (permission.hasOwnProperty("urn") && permission.urn.toString().toLowerCase().includes(filter)) {
                    updatedPermissions.push(permission);
                } else if (permission.hasOwnProperty("description") && permission.description.toString().includes(filter)) {
                    updatedPermissions.push(permission);
                }
            }
            setFilteredPermissions(updatedPermissions);
        } else {
            setFilteredPermissions(props.permissions);
        }
    }, [filter, JSON.stringify(props.permissions)]);

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon secondary onClick={() => props.setNewClicked(true)} fluid><Icon name="plus"/> Create New</Button>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.onClickRefresh()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72.5vh", overflowY: "auto"}}>
                    {
                        props.loading ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading Permissions...
                                </Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                {
                                    filteredPermissions.length > 0 ?
                                        filteredPermissions.map(permission=>
                                            <Menu.Item
                                                active={props.selectedPermission.urn === permission.urn}
                                                onClick={
                                                    () => {
                                                        console.log(permission);
                                                        props.setSelectedPermission(permission);
                                                    }
                                                }
                                                fluid
                                            >
                                                <h5>{permission.urn}</h5>
                                                <>{permission.description}</>
                                            </Menu.Item>
                                        ) :
                                        <Message icon color="yellow">
                                            <Icon name="warning" />
                                            <Message.Content>No permissions match your current search filter.</Message.Content>
                                        </Message>
                                }
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};
