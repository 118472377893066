import React, {useState, useEffect} from "react";
import {Button, Container, Form, Icon, Input, Message, Modal, Popup, Select, Table, TextArea} from "semantic-ui-react";

export default function PermissionDetailView(props) {
    const [currentUrn, setCurrentUrn] = useState("");
    const [currentService, setCurrentService] = useState("");
    const [currentModule, setCurrentModule] = useState("");
    const [currentScope, setCurrentScope] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    const [initialDescription, setInitialDescription] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");
    const [currentUsers, setCurrentUsers] = useState([]);
    const [otherUsers, setOtherUsers] = useState([]);
    const [otherUserOptions, setOtherUserOptions] = useState([]);
    const [selectedUserToAdd, setSelectedUserToAdd] = useState("");
    const [serviceOptions, setServiceOptions] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [scopeOptions, setScopeOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [initialUrn, setInitialUrn] = useState("");
    const [urnAlreadyInUse, setUrnAlreadyInUse] = useState(false);
    const [permissionUpdated, setPermissionUpdated] = useState(false);

    useEffect(function updateUsersArrays() {
        const updatedCurrentUsers = [];
        const updatedOtherUsers = [];
        if (!props.newClicked && currentUrn.length > 0) {
            for (const user of props.allUsers) {
                console.log("PermissionDetailView.updateUsersArray: user", user);
                if (Array.isArray(user.authz) && user.authz.includes(currentUrn)) {
                    updatedCurrentUsers.push(Object.assign({}, user));
                } else {
                    updatedOtherUsers.push(Object.assign({}, user));
                }
            }
        }

        console.log("PermissionDetailView.updateUsersArray: updated current users", updatedCurrentUsers, "currentUrn", currentUrn);
        updatedCurrentUsers.sort((user1, user2) => user1.username.localeCompare(user2.username));
        updatedOtherUsers.sort((user1, user2) => user1.username.localeCompare(user2.username));
        setCurrentUsers(updatedCurrentUsers);
        setOtherUsers(updatedOtherUsers);
    }, [JSON.stringify(props.allUsers), JSON.stringify(props.selectedPermission), currentUrn]);

    useEffect(function updatePermissionUpdated() {
        setPermissionUpdated(currentDescription !== initialDescription);
    }, [currentDescription, initialDescription]);

    useEffect(function duplicateUrnCheck() {
        let updatedUrnAlreadyInUse = false;
        for (const urn of props.existingPermissionUrns) {
            if (urn === currentUrn) {
                updatedUrnAlreadyInUse = true;
            }
        }
        setUrnAlreadyInUse(updatedUrnAlreadyInUse);
    }, [JSON.stringify(props.existingPermissionUrns), currentUrn, props.newClicked]);

    useEffect(function updateServiceOptions() {
        const updatedServiceOptions = [{key: "all", text: "all", value: "all"}];
        console.log("services: ", props.permissionsMetadata);

        for (const service of Object.keys(props.permissionsMetadata)) {
            updatedServiceOptions.push({key: service, text: service, value: service});
        }

        setServiceOptions(updatedServiceOptions);
        console.log(updatedServiceOptions);
    }, [JSON.stringify(props.permissionsMetadata)]);

    useEffect(function updateModuleOptions() {
        const updatedModuleOptions = [{key: "all", text: "all", value: "all"}];
        if (currentService && props.permissionsMetadata.hasOwnProperty(currentService)) {
            for (const module of Object.keys(props.permissionsMetadata[currentService])) {
                updatedModuleOptions.push({key: module, text: module, value: module});
            }
        }

        setModuleOptions(updatedModuleOptions);
    }, [JSON.stringify(props.permissionsMetadata), currentService]);

    useEffect(function updateScopeOptions() {
        const updatedScopeOptions = [{key: "all", text: "all", value: "all"}];

        if (currentModule && props.permissionsMetadata.hasOwnProperty(currentService) && props.permissionsMetadata[currentService].hasOwnProperty(currentModule)) {
            for (const scope of props.permissionsMetadata[currentService][currentModule]) {
                updatedScopeOptions.push({key: scope, text: scope, value: scope});
            }
        }

        setScopeOptions(updatedScopeOptions);
    }, [JSON.stringify(props.permissionsMetadata), currentModule]);

    useEffect(function updateRoleOptions() {
        const updatedRoleOptions = [];

        if (props.rolesMetadata) {
            for (const role of props.rolesMetadata) {
                updatedRoleOptions.push({key: role, text: role, value: role});
            }
        }

        setRoleOptions(updatedRoleOptions);
    }, [JSON.stringify(props.rolesMetadata)]);

    useEffect(function updateSelectedPermission() {
        setCurrentUrn(props.selectedPermission.urn || "");
        setInitialUrn(props.selectedPermission.urn);
        setCurrentService(props.selectedPermission.service || "");
        setCurrentModule(props.selectedPermission.module || "");
        setCurrentScope(props.selectedPermission.scope || "");
        setCurrentRole(props.selectedPermission.role || "");
        setCurrentDescription(props.selectedPermission.description || "");
        setInitialDescription(props.selectedPermission.description || "");
    }, [JSON.stringify(props.selectedPermission)]);

    useEffect(() => {
        console.log("current urn: ", currentUrn);
        console.log("current users: ", currentUsers);
    }, [currentUrn]);

    useEffect(function updateCurrentURN() {
        if (!initialUrn && currentService && currentModule && currentScope && currentRole) {
            setCurrentUrn(`urn:${currentService}:${currentModule}:${currentScope}:${currentRole}`);
        }
    }, [initialUrn, currentService, currentModule, currentScope, currentRole]);

    useEffect(function updateOtherUserOptions() {
        const updatedOtherUserOptions = [];

        for (const user of otherUsers) {
            updatedOtherUserOptions.push({key: user.okta_id, text: `[${user.okta_id}] ${user.username}`, value: user.okta_id});
        }
        setOtherUserOptions(updatedOtherUserOptions);
    }, [JSON.stringify(otherUsers)]);

    return (
        <Container>
            <Modal />
            {
                props.loadingUsers ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>
                            Loading users...
                        </Message.Content>
                    </Message> :
                props.loadingPermissions ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading permissions...</Message.Content>
                    </Message> :
                props.savingPermission ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Updating permission...</Message.Content>
                    </Message> :
                Object.keys(props.selectedPermission).length < 1 && !props.newClicked ?
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>Please select a permission to continue.</Message.Content>
                    </Message> :
                    <Container fluid>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <Popup
                                        content="This permission already exists."
                                        open={urnAlreadyInUse && props.newClicked}
                                        trigger={
                                            <Button
                                                onClick={() => props.savePermission(currentUrn, currentService, currentModule, currentScope, currentRole, currentDescription)}
                                                disabled={(urnAlreadyInUse && props.newClicked) || !permissionUpdated}
                                            >Save</Button>
                                        }
                                        position="right center"
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="URN"
                                    control={Input}
                                    value={currentUrn}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Service"
                                    control={Select}
                                    options={serviceOptions}
                                    value={currentService}
                                    readOnly={!props.newClicked}
                                    disabled={!props.newClicked}
                                    onChange={(event, {value}) => setCurrentService(value)}
                                />
                                <Form.Field
                                    label="Module"
                                    control={Select}
                                    options={moduleOptions}
                                    value={currentModule}
                                    readOnly={!props.newClicked}
                                    disabled={!props.newClicked}
                                    onChange={(event, {value}) => setCurrentModule(value)}
                                />
                                <Form.Field
                                    label="Scope"
                                    control={Select}
                                    options={scopeOptions}
                                    value={currentScope}
                                    readOnly={!props.newClicked}
                                    disabled={!props.newClicked}
                                    onChange={(event, {value}) => setCurrentScope(value)}
                                />
                                <Form.Field
                                    label="Role"
                                    control={Select}
                                    options={roleOptions}
                                    value={currentRole}
                                    onChange={(event, {value}) => setCurrentRole(value)}
                                    readOnly={!props.newClicked}
                                    disabled={!props.newClicked}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Description"
                                    control={TextArea}
                                    value={currentDescription}
                                    onChange={(event, {value}) => setCurrentDescription(value)}
                                />
                            </Form.Group>
                        </Form>
                        {
                            props.newClicked ? "" :
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Okta ID</Table.HeaderCell>
                                            <Table.HeaderCell>User Email</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Remove Permission from User</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan={2}>
                                                <Form>
                                                    <Form.Group widths="equal">
                                                        <Form.Field
                                                            label=""
                                                            control={Select}
                                                            value={selectedUserToAdd}
                                                            onChange={(event, {value}) => setSelectedUserToAdd(value)}
                                                            options={otherUserOptions}
                                                            search
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    <Form.Group>
                                                        <Button
                                                            color="green"
                                                            fluid
                                                            onClick={() => props.givePermissionToUser(selectedUserToAdd)}
                                                            content="Add User"
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                        {
                                            currentUsers.map(user =>
                                                <Table.Row>
                                                    <Table.Cell>{user.okta_id}</Table.Cell>
                                                    <Table.Cell>{user.username}</Table.Cell>
                                                    <Table.Cell>
                                                        <Button icon fluid onClick={() => props.removePermissionFromUser(user.okta_id)}><Icon name="minus"/></Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                        }
                    </Container>
            }
        </Container>
    );
};
