import React, {useEffect, useState} from 'react';
import ReactTable from "react-table-v6";
import {Dropdown, Grid, Button, Form, Message, Icon, Container, Card, Divider} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import parser from "ua-parser-js";

export default function UserMasterDetailView(props) {
    const [permissions, setPermissions] = useState([]);
    const [permissionUpdates, setPermissionUpdates] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [userSessions, setUserSessions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingUserPermissions, setLoadingUserPermissions] = useState(false);
    const [loadingUserSessions, setLoadingUserSessions] = useState(false);
    const [updatingUserPermissions, setUpdatingUserPermissions] = useState(false);
    const [users, setUsers] = useState([]);
    const [getUsersError, setGetUsersError] = useState("");
    const [getPermissionsError, setGetPermissionsError] = useState("");
    const [getUserSessionsError, setGetUserSessionsError] = useState("");
    const [getUserPermissionsError, setGetUserPermissionsError] = useState("");
    const [updateUserPermissionsError, setUpdateUserPermissionsError] = useState("");
    const [updateUserPermissionsSuccess, setUpdateUserPermissionsSuccess] = useState("");

    useEffect(function loadPermissionsAndUsers() {
        getPermissions();
        getUsers();
    }, []);

    useEffect(function updateSelectedUserInfo() {
        getUserPermissions();
        getUserSessions();
    }, [JSON.stringify(selectedUser)]);

    const getPermissions = () => {
        setGetPermissionsError("");
        props.AuthDataProvider.getPermissions().then(response => {
            console.log("permissions: ", response);
            let allPermissions = [];
            if (response.hasOwnProperty("error")) {
                setGetPermissionsError("Error getting all permissions. You must be on the WarnerMedia network to modify permissions.");
            } else {
                response.sort();
                response.forEach(permission => {
                    allPermissions.push({
                        value: permission,
                        key: permission,
                        text: permission
                    });
                });
            }

            console.log("all permissions: ", allPermissions);
            setPermissions(allPermissions);
        });
    };

    const getUsers = () => {
        setGetUsersError("");
        setLoading(true);

        props.AuthDataProvider.getUsers().then(response => {
            if (response.hasOwnProperty("error")) {
                setGetUsersError("Error getting all users. You must be on the WarnerMedia network in order to view and modify user permissions.");
            } else {
                const users = [];
                for (const user of response) {
                    users.push(Object.assign({_id: "", okta_id: "", username: ""}, user));
                }
                setUsers(users);
            }
        }).catch(error => {
            console.error(error);
            setGetUsersError("Error getting all users. You must be on the WarnerMedia network in order to view and modify user permissions.");
        }).finally(() => {
            setLoading(false);
        });
    };

    const getUserPermissions = () => {
        setGetUserPermissionsError("");

        if (selectedUser.hasOwnProperty("okta_id") && selectedUser.okta_id.length > 0) {
            const userId = selectedUser.okta_id;
            setLoadingUserPermissions(true);

            console.log("UserMasterDetailView.getUserPermissions: getting user permissions for ", userId);
            props.AuthDataProvider.getUserPermissions(userId).then(response => {
                console.log("(UserMasterDetailView getUserPermissions) permissions: ", userId, response);
                if (response.hasOwnProperty("error")) {
                    setGetUserPermissionsError("Error getting permissions for user. You need to be on the WarnerMedia network to view and modify user permissions.");
                } else {
                    const uniquePermissions = Array.from(new Set([...response]));

                    setSelectedUserPermissions(uniquePermissions);
                }
            }).catch(error => {
                console.error(error);
                setGetUserPermissionsError("Error getting permissions for user. You need to be on the WarnerMedia network to view and modify user permissions.");
            }).finally(() => {
                setLoadingUserPermissions(false);
            });
        } else {
            setSelectedUserPermissions([]);
        }
    };

    const getUserSessions = () => {
        if (selectedUser.hasOwnProperty("okta_id") && selectedUser.okta_id.length > 0) {
            const userId = selectedUser.okta_id;
            setLoadingUserSessions(true);
            setGetUserSessionsError("");

            props.AuthDataProvider.getUserSessions(userId).then(response => {
                console.log(response);
                const userSessions = [];
                for (const session of response.sessions) {
                    userSessions.push(Object.assign({isDeleted: false, isDeleting: false}, session));
                }
                setUserSessions(userSessions);
            }).catch(error => {
                console.error(error);
                setGetUserSessionsError("There was an error getting sessions for this user.");
                setUserSessions([]);
            }).finally(() => {
                setLoadingUserSessions(false);
            });
        } else {
            setUserSessions([]);
        }
    };

    const updateUser = () => {
        let userId = selectedUser.okta_id;

        setUpdateUserPermissionsError("");
        setUpdateUserPermissionsSuccess("");

        if (Object.keys(props.user).length === 0) {
            setUpdateUserPermissionsError("No login data was detected. Try navigating to this page again from the dashboard.");
        } else {
            setUpdatingUserPermissions(true);

            props.AuthDataProvider.updateUserPermissions(userId, selectedUserPermissions).then(response => {
                if (response.message) {
                    setUpdateUserPermissionsSuccess(response.message);
                }
                else if (response.error) {
                    setUpdateUserPermissionsError(response.error);
                }
            }).catch(error => {
                console.error(error);
                setUpdateUserPermissionsError("There was an error updating this user's permissions.");
            }).finally(() => {
                setUpdatingUserPermissions(false);
            });
        }
    };

    const deleteUserSession = (sessionID, index) => {
        props.AuthDataProvider.deleteUserSession(sessionID).then(response => {
            console.log("UserMasterDetailView.deleteUserSession response: ", response);
            if (response.success) {
                const updatedSessions = [];
                for (let i = 0; i < userSessions.length; i++) {
                    if (index === i) {
                        const updatedSession = Object.assign({}, userSessions[i], {isDeleting: false, isDeleted: true});
                        updatedSessions.push(updatedSession);
                    }
                    else {
                        updatedSessions.push(userSessions[i]);
                    }
                }
                setUserSessions(updatedSessions);
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            console.log("UserMasterDetailView.deleteUserSession user sessions: ", userSessions);
        });
    };

    return (
        <Grid style={{height: '90vh'}}>
            <Grid.Row>
                <Grid.Column width={8} className='masterContainer'>
                    <ReactTable
                        columns={[
                            { Header: "ID", accessor: "_id" },
                            { Header: 'Okta ID', accessor: 'okta_id' },
                            { Header: 'User Name', accessor: 'username' }
                        ]}
                        data={users}
                        loading={loading}
                        filterable
                        defaultSorted={[
                            {
                                id: "username",
                                desc: false
                            }
                        ]}
                        defaultFilterMethod={(filter, rows) => rows[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick(event, handleOriginal) {
                                    console.log("rowinfo.row: ", rowInfo.row);
                                    setSelectedUser(rowInfo.row._original);
                                    setUpdateUserPermissionsError("");
                                    setUpdateUserPermissionsSuccess("");
                                    setGetUserPermissionsError("");
                                },
                                style: {
                                    background: rowInfo && rowInfo.row && selectedUser.hasOwnProperty("_id") && selectedUser._id === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                }
                            }
                        }}
                        style={{height: '83vh'}}
                    />
                </Grid.Column>
                <Grid.Column width={8} className='detailsContainer'>
                    {
                        getUsersError.length > 0 ?
                            <Message icon="exclamation" color="red" header="Error" content={getUsersError} /> : ""
                    }
                    {
                        getPermissionsError.length > 0 ?
                            <Message icon="exclamation" color="red" header="Error" content={getPermissionsError} /> : ""
                    }
                    {
                        updateUserPermissionsError.length > 0 ?
                            <Message icon="exclamation" color="red" header="Error" content={updateUserPermissionsError} /> :
                            updateUserPermissionsSuccess.length > 0 ?
                                <Message icon="success" color="green" header="Success" content={updateUserPermissionsSuccess} /> :""
                    }
                    {
                        getUserPermissionsError.length ?
                            <Message icon="exclamation" color="red" header="Error" content={getUserPermissionsError} /> : ""
                    }
                    {
                        loadingUserPermissions ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>Loading user permissions...</Message.Content>
                            </Message> : ""
                    }
                    {
                        updatingUserPermissions ?
                            <Message icon color="blue">
                                <Icon name="spinner" loading />
                                <Message.Content>Updating permissions...</Message.Content>
                            </Message> : ""
                    }
                    {
                        typeof selectedUser !== "object" || Object.keys(selectedUser).length < 1 ?
                            <Message icon="info" color="yellow" content="Please select a user to continue." /> :
                            <Container>
                                <Form onSubmit={updateUser}>
                                    <Form.Field>
                                        <label>Permissions Management</label>
                                        <Dropdown
                                            fluid
                                            multiple
                                            allowAdditions
                                            search
                                            selection
                                            options={permissions}
                                            value={selectedUserPermissions}
                                            onChange={(event, { value }) => setSelectedUserPermissions(value)}
                                            onAddItem={(event, { value }) => {
                                                const updatedPermissions = permissions.slice();
                                                updatedPermissions.push({key: value, text: value, value});
                                                setPermissions(updatedPermissions);
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button primary type="submit">Save Permissions</Button>
                                        <Button secondary as={Link} to={{pathname: "https://docsprod.turner.com/pages/viewpage.action?spaceKey=MPDTAS&title=On-Boarding+New+Users"}} target="_blank" content="Onboarding Docs"/>
                                    </Form.Field>
                                </Form>
                                {
                                    loadingUserSessions ?
                                        <Message icon color="yellow">
                                            <Icon name="spinner" loading />
                                            <Message.Content>Loading user sessions...</Message.Content>
                                        </Message> :
                                    getUserSessionsError.length > 0 ?
                                        <Message icon="exclamation" color="red" header="Error" content={getUserSessionsError}/> :
                                    userSessions.length < 1 ?
                                        <Message icon="info" color="yellow" header="No User Sessions" content="This user has no active sessions currently." /> :
                                        <Container>
                                            <Divider horizontal section>User Sessions</Divider>
                                            <Card.Group itemsPerRow={2}>
                                                {
                                                    userSessions.map(
                                                        (session, index) => {
                                                            const userAgentString = session.userAgent;
                                                            const userAgent = parser(userAgentString);
                                                            const legibleUserAgent = userAgentString ? `${userAgent.browser.name} ${userAgent.browser.major} on ${userAgent.os.name} ${userAgent.os.version}` : "Unknown";
                                                            return (
                                                                <Card>
                                                                    {
                                                                        session.isDeleted ?
                                                                            <Card.Content>
                                                                                <Card.Header>{session._id}</Card.Header>
                                                                                <Card.Meta>This session has been deleted.</Card.Meta>
                                                                            </Card.Content> :
                                                                            <Card.Content>
                                                                                <Card.Header as="h4">{session._id}</Card.Header>
                                                                                <Card.Meta>Expires: {new Date(session.expiration * 1000).toLocaleString()}</Card.Meta>
                                                                                <Card.Description>User Agent: {legibleUserAgent}</Card.Description>
                                                                            </Card.Content>
                                                                    }
                                                                    <Card.Content>
                                                                        <Card.Description>
                                                                            {
                                                                                session.isDeleted ? "" :
                                                                                    <Button
                                                                                        color="red"
                                                                                        floated="right"
                                                                                        onClick={
                                                                                            () => {
                                                                                                const updatedSessions = [];
                                                                                                for (let i = 0; i < userSessions.length; i++) {
                                                                                                    if (index === i) {
                                                                                                        const updatedSession = Object.assign({}, userSessions[i], {isDeleting: true, isDeleted: false});
                                                                                                        updatedSessions.push(updatedSession);
                                                                                                    } else {
                                                                                                        updatedSessions.push(userSessions[i]);
                                                                                                    }
                                                                                                }
                                                                                                console.log("UserMasterDetailView.render: updated sessions: ", updatedSessions);
                                                                                                setUserSessions(updatedSessions);
                                                                                                deleteUserSession(session._id, index);
                                                                                            }
                                                                                        }
                                                                                    >{session.isDeleting === true ? <Icon name="spinner" loading /> : "Delete"}</Button>
                                                                            }
                                                                        </Card.Description>
                                                                    </Card.Content>
                                                                </Card>
                                                            );
                                                        }
                                                    )
                                                }
                                            </Card.Group>
                                        </Container>
                                }
                            </Container>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
