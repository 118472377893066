import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import TokenStreamSettingsSelector from "./TokenStreamSettingsSelector";
import TokenStreamSettingsDetailView from "./TokenStreamSettingsDetailView";
import TokenMetadataDataProvider from "../../../Services/TokenMdDataProvider";


export default function TokenStreamSettingsMasterDetailView(props) {
    const [streamConcurrencySettings, setStreamConcurrencySettings] = useState([]);
    const [selectedStreamConcurrencySetting, setSelectedStreamConcurrencySetting] = useState({});
    const [ruleIds, setRuleIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [newClicked, setNewClicked] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isSelectedSettingsUpdated, setIsSelectedSettingsUpdated] = useState(false);
    const [settingsRearranged, setSettingsRearranged] = useState(false);
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function initializeStreamConcurrencySettings() {
        TokenMetadataDataProvider.init({baseURL: process.env.TOKEN_MD_URL});
        refreshStreamConcurrencySettings();
    }, []);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = false;
        for (const permission of props.permissions) {
            updatedUserCanEdit ||= permission.service === props.service && permission.module === props.module && permission.role === "editor";
            if (updatedUserCanEdit) {
                break;
            }
        }

        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.permissions), JSON.stringify(props.userPermissions)]);

    useEffect(function generateRuleIds() {
        const updatedRuleIds = [];
        for (const setting of streamConcurrencySettings) {
            if (setting.hasOwnProperty("ruleId") && selectedStreamConcurrencySetting.hasOwnProperty("ruleId") && setting.ruleId !== selectedStreamConcurrencySetting.ruleId) {
                updatedRuleIds.push(setting.ruleId);
            }
        }
        setRuleIds(updatedRuleIds);
    }, [JSON.stringify(streamConcurrencySettings), JSON.stringify(selectedStreamConcurrencySetting)]);

    useEffect(function onSelectSettings() {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        setNewClicked(false);
        setUnsavedChanges(settingsRearranged);
    }, [newClicked, JSON.stringify(selectedStreamConcurrencySetting)]);

    useEffect(function onClickNew() {
        if (newClicked) {
            const newSetting = {
                ruleId: "",
                index: streamConcurrencySettings.length
            };
            setStreamConcurrencySettings(streamConcurrencySettings.slice().concat(Object.assign({}, newSetting)));
            setSelectedStreamConcurrencySetting(Object.assign({}, newSetting));
            setSaveSuccessMessage("");
        }
    }, [newClicked]);

    useEffect(function onSettingsUpdate() {
        setSettingsRearranged(false);
        for (let i = 0; i < streamConcurrencySettings.length; i++) {
            if (streamConcurrencySettings[i].index !== i) {
                setSettingsRearranged(true);
                break;
            }
        }
    }, [JSON.stringify(streamConcurrencySettings)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(settingsRearranged || isSelectedSettingsUpdated);
    }, [settingsRearranged, isSelectedSettingsUpdated]);

    const refreshStreamConcurrencySettings = () => {
        setLoading(true);
        setLoadingErrorMessage("");
        TokenMetadataDataProvider.getMetadata().then(response => {
            response.json().then(json => {
                if (json.hasOwnProperty("stream_settings")) {
                    const streamSettings = json.stream_settings.slice();
                    for (let i = 0; i < streamSettings.length; i++) {
                        streamSettings[i].index = i;
                        if (selectedStreamConcurrencySetting.hasOwnProperty("ruleId") && selectedStreamConcurrencySetting.ruleId === streamSettings[i].ruleId) {
                            setSelectedStreamConcurrencySetting(Object.assign({}, streamSettings[i]));
                        }
                    }
                    setStreamConcurrencySettings(streamSettings);
                } else {
                    setStreamConcurrencySettings([]);
                    setLoadingErrorMessage("There was an error loading stream concurrency settings.");
                }
            });
        }).catch(error => {
            console.error(error);
            setLoadingErrorMessage("There was an error loading stream concurrency settings.");
        }).finally(() => {
            setLoading(false);
        });
    };

    const onClickRefresh = () => {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        refreshStreamConcurrencySettings();
    }

    const onClickSave = streamCountingSetting => {
        console.log(streamCountingSetting);
        setSaveErrorMessage("");
        setLoadingErrorMessage("");
        if (streamCountingSetting.hasOwnProperty("sc")) {
            if (streamCountingSetting.sc.streams < -1) {
                setSaveErrorMessage("The minimum number of streams is -1.");
                return;
            }
            if (!["first", "last"].includes(streamCountingSetting.sc.order)) {
                setSaveErrorMessage("This setting has an invalid stream counting order value.");
                return;
            }
        }

        if ((Array.isArray(streamCountingSetting.requestor) && streamCountingSetting.requestor.length < 1) && (Array.isArray(streamCountingSetting.resource) && streamCountingSetting.resource.length < 1)) {
            setSaveErrorMessage("At least one requestor or resource must be provided.");
            return;
        }

        if (!Array.isArray(streamCountingSetting.path) || streamCountingSetting.path.length < 1) {
            setSaveErrorMessage("At least one path must be provided.");
            return;
        }

        const updatedStreamSettings = streamConcurrencySettings.slice();

        for (let i = 0; i < updatedStreamSettings.length; i++) {
            if (updatedStreamSettings[i].index === streamCountingSetting.index) {
                updatedStreamSettings[i] = Object.assign({}, streamCountingSetting);
                break;
            }
        }

        for (const setting of updatedStreamSettings) {
            delete setting.index;
        }

        const metadata = {
            "stream_settings": updatedStreamSettings
        }

        setSaving(true);
        TokenMetadataDataProvider.setMetadata(metadata).then(response => {
            response.json().then(json => {
                setSaveSuccessMessage("Stream concurrency settings were saved successfully.");
                setUnsavedChanges(false);
            });
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving stream concurrency settings.");
        }).finally(() => {
            setSaving(false);
            refreshStreamConcurrencySettings();
        });
    };

    const onClickDelete = index => {
        setSaveErrorMessage("");
        setSaveSuccessMessage("");

        const updatedStreamConcurrencySettings = streamConcurrencySettings.slice();
        updatedStreamConcurrencySettings.splice(index, 1);

        const metadata = {
            "stream_settings": updatedStreamConcurrencySettings
        };

        setSaving(true);
        TokenMetadataDataProvider.setMetadata(metadata).then(response => {
            response.json().then(json => {
                setSaveSuccessMessage("The selected stream concurrency setting was successfully deleted.");
            });
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error deleting the selected stream concurrency setting.");
        }).finally(() => {
            setSaving(false);
            refreshStreamConcurrencySettings();
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={4}>
                <TokenStreamSettingsSelector
                    streamConcurrencySettings={streamConcurrencySettings}
                    setStreamConcurrencySettings={setStreamConcurrencySettings}
                    refreshStreamConcurrencySettings={onClickRefresh}
                    selectedStreamConcurrencySetting={selectedStreamConcurrencySetting}
                    setSelectedStreamConcurrencySetting={setSelectedStreamConcurrencySetting}
                    toast={props.toast}
                    setNewClicked={setNewClicked}
                    setUnsavedChanges={setUnsavedChanges}
                    service={props.service}
                    module={props.module}
                    permissions={props.permissions}
                    user={props.user}
                />
            </Grid.Column>
            <Grid.Column width={12} style={{maxHeight: "92vh", overflowY: "auto", overflowX: "hidden"}}>
                <TokenStreamSettingsDetailView
                    loading={loading}
                    loadingErrorMessage={loadingErrorMessage}
                    saving={saving}
                    saveErrorMessage={saveErrorMessage}
                    saveSuccessMessage={saveSuccessMessage}
                    newClicked={newClicked}
                    selectedStreamConcurrencySetting={selectedStreamConcurrencySetting}
                    onClickSave={onClickSave}
                    onClickDelete={onClickDelete}
                    unsavedChanges={unsavedChanges}
                    isSelectedSettingsUpdated={isSelectedSettingsUpdated}
                    setIsSelectedSettingsUpdated={setIsSelectedSettingsUpdated}
                    ruleIds={ruleIds}
                    service={props.service}
                    module={props.module}
                    permissions={props.permissions}
                    user={props.user}
                    userCanEdit={userCanEdit}
                />
            </Grid.Column>
        </Grid>
    );
};
