import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import PermissionMasterDetailView from "./PermissionMasterDetailView";
import UserMasterDetailView from "./UserMasterDetailView";
import AuthDataProvider from "../../../Services/AuthDataProvider";

AuthDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});


export default function UserManagementMasterDetailView(props) {
    return (
        <Tab
            panes={[
                {
                    menuItem: {
                        key: "organize-by-user",
                        content: "User Management"
                    },
                    render: () =>
                        <UserMasterDetailView
                            AuthDataProvider={AuthDataProvider}
                            user={props.user}
                        />
                },
                {
                    menuItem: {
                        key: "organize-by-permission",
                        content: "Permissions Management"
                    },
                    render: () =>
                        <PermissionMasterDetailView
                            AuthDataProvider={AuthDataProvider}
                        />
                }
            ]}
        />
    )
};
