import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import PermissionSelector from "./PermissionSelector";
import PermissionDetailView from "./PermissionDetailView";
import SynapseDataProvider from "../../../Services/SynapseMetadataDataProvider";

SynapseDataProvider.init(process.env.SYNAPSE_CORE_URL);


export default function PermissionMasterDetailView(props) {
    const [loadingPermissions, setLoadingPermissions] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [savingPermission, setSavingPermission] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState({});
    const [newClicked, setNewClicked] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [userDictionary, setUserDictionary] = useState({});
    const [permissionsMetadata, setPermissionsMetadata] = useState({});
    const [rolesMetadata, setRolesMetadata] = useState([]);
    const [existingPermissionUrns, setExistingPermissionUrns] = useState([]);

    useEffect(function initializeUsersAndPermissions() {
        refreshUsers();
        refreshPermissions();
        refreshMetadata();
    }, []);

    useEffect(function onClickCreateNew() {
        if (newClicked) {
            setSelectedPermission({});
        }
    }, [newClicked]);

    useEffect(function onSelectPermission() {
        if (Object.keys(selectedPermission).length > 0) {
            setNewClicked(false);
        }
    }, [JSON.stringify(selectedPermission), JSON.stringify(permissions)]);

    useEffect(function updatePermissionURNs() {
        const updatedPermissionURNs = [];

        for (const permission of permissions) {
            if (permission.hasOwnProperty("urn")) {
                updatedPermissionURNs.push(permission.urn);
            }
        }

        setExistingPermissionUrns(updatedPermissionURNs);
    }, [JSON.stringify(permissions)]);

    useEffect(function onPermissionRefresh() {
        let updatedSelectedPermission = {};
        if (selectedPermission.hasOwnProperty("urn")) {
            for (const permission of permissions) {
                if (permission.urn === selectedPermission.urn) {
                    updatedSelectedPermission = Object.assign({}, permission);
                }
            }
        }

        setSelectedPermission(updatedSelectedPermission);
    }, [JSON.stringify(permissions)]);

    const refreshMetadata = () => {
        SynapseDataProvider.getMetadata().then(response => {
            if (response.error) {
                return;
            }
            if (response.hasOwnProperty("metadata") && response.metadata.hasOwnProperty("permissions")) {
                setPermissionsMetadata(response.metadata.permissions);
            } else {
                setPermissionsMetadata({});
            }

            if (response.hasOwnProperty("metadata") && response.metadata.hasOwnProperty("roles")) {
                setRolesMetadata(response.metadata.roles);
            } else {
                setRolesMetadata([]);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const refreshUsers = () => {
        setLoadingUsers(true);
        const updatedUserDictionary = {};
        props.AuthDataProvider.getUsers().then(response => {
            if (response.hasOwnProperty("error")) {
                return;
            }

            console.log("all users", response);

            response.sort((user1, user2) => user1.username.localeCompare(user2.username));
            setAllUsers(response);

            for (const user of response) {
                updatedUserDictionary[user.okta_id] = user;
            }

            setUserDictionary(updatedUserDictionary);
            console.log("updated user dictionary", updatedUserDictionary);
        }).finally(() => {
            setLoadingUsers(false);
        });
    };

    const refreshPermissions = () => {
        setLoadingPermissions(true);
        props.AuthDataProvider.getAllPermissions().then(response => {
            console.log("PermissionMasterDetailView.refreshPermissions permissions: ", response);
            const updatedPermissions = response.slice();
            updatedPermissions.sort((permission1, permission2) => {
                return permission1.urn.localeCompare(permission2.urn);
            });
            setPermissions(updatedPermissions);
            if (selectedPermission.hasOwnProperty("urn")) {
                for (const permission of permissions) {
                    if (permission.urn === selectedPermission.urn) {
                        setSelectedPermission(permission);
                        break;
                    }
                }
            }
        }).finally(() => {
            setLoadingPermissions(false);
        });
    };

    const givePermissionToUser = selectedUserId => {
        const permissionSelected = selectedPermission && selectedPermission.hasOwnProperty("urn");
        const validUserSelected = userDictionary.hasOwnProperty(selectedUserId);

        console.log("PermissionMasterDetailView.givePermissionToUser: permission selected?", permissionSelected);
        console.log("PermissionMasterDetailView.givePermissionToUser: valid user selected?", validUserSelected, selectedUserId);
        if (permissionSelected && validUserSelected) {
            const selectedUser = userDictionary[selectedUserId];
            const userHasPermission = selectedUser.authz.includes(selectedPermission.urn);
            if (!userHasPermission) {
                setSavingPermission(true);
                props.AuthDataProvider.updateUserPermissions(selectedUserId, selectedUser.authz.concat([selectedPermission.urn])).then(() => {
                    refreshUsers();
                    refreshPermissions();
                }).finally(() => {
                    setSavingPermission(false);
                });
            }
        }
    };

    const onClickRefresh = () => {
        refreshPermissions();
        refreshUsers();
        refreshMetadata();
    }

    const removePermissionFromUser = selectedUserId => {
        const permissionSelected = selectedPermission && selectedPermission.hasOwnProperty("urn");
        const validUserSelected = userDictionary.hasOwnProperty(selectedUserId);

        console.log("PermissionMasterDetailView.givePermissionToUser: permission selected?", permissionSelected);
        console.log("PermissionMasterDetailView.givePermissionToUser: valid user selected?", validUserSelected, selectedUserId);
        if (permissionSelected && validUserSelected) {
            const selectedUser = userDictionary[selectedUserId];
            const userHasPermission = selectedUser.authz.includes(selectedPermission.urn);
            if (userHasPermission) {
                const updatedUserPermissions = [];
                for (const permission of selectedUser.authz) {
                    if (permission !== selectedPermission.urn) {
                        updatedUserPermissions.push(permission);
                    }
                }
                setSavingPermission(true);
                props.AuthDataProvider.updateUserPermissions(selectedUserId, updatedUserPermissions).then(() => {
                    refreshUsers();
                    refreshPermissions();
                }).finally(() => {
                    setSavingPermission(false);
                });
            }
        }
    };

    const savePermission = (urn, service, module, scope, role, description) => {
        setSavingPermission(true);
        let urnToSend = urn.startsWith("urn:") ? urn : `urn:${urn}`;
        props.AuthDataProvider.createPermission(urnToSend, service, module, scope, role, description).then(response => {
            console.log(response);
        }).then(() => {
            refreshPermissions();
            refreshUsers();
            setNewClicked(false);
        }).finally(() => {
            setSavingPermission(false);
        });
    };

    return (
        <Grid style={{height: '79vh'}} className="masterContainer">
            <Grid.Column width={4}>
                <PermissionSelector
                    permissions={permissions}
                    selectedPermission={selectedPermission}
                    setSelectedPermission={setSelectedPermission}
                    setNewClicked={setNewClicked}
                    onClickRefresh={onClickRefresh}
                />
            </Grid.Column>
            <Grid.Column width={12} style={{maxHeight: "79vh", overflowY: "auto", overflowX: "hidden"}}>
                <PermissionDetailView
                    loadingUsers={loadingUsers}
                    loadingPermissions={loadingPermissions}
                    selectedPermission={selectedPermission}
                    allUsers={allUsers}
                    givePermissionToUser={givePermissionToUser}
                    removePermissionFromUser={removePermissionFromUser}
                    newClicked={newClicked}
                    savePermission={savePermission}
                    savingPermission={savingPermission}
                    permissionsMetadata={permissionsMetadata}
                    rolesMetadata={rolesMetadata}
                    existingPermissionUrns={existingPermissionUrns}
                />
            </Grid.Column>
        </Grid>
    )
};
